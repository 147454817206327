<template>
    <div class="statistic">
        <div class="sidebar-container">
            <Sidebar title="统计维度" :data="routeList"></Sidebar>
        </div>
        <div class="statistic-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/sidebar';
import { traverse } from '@/utils/handle.js';

export default {
    components: {
        Sidebar
    },
    data(){
        return {
            routeList: [],
            currentRouteName: 'statistic',      //  当前路由
        }
    },
    created(){
        this.getRouteList();
    },
    methods: {
        getRouteList(){
            traverse(this.$router.options.routes,route => {
                if(route.children && route.children.find(item => item.name === this.$route.name)){
                    this.routeList = route.children;
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
.statistic{
    width: 100%;
    display: flex;
    .sidebar-container{
        width: 250px;
        margin-right: 10px;
        padding: 2px;
    }
    .statistic-content{
        width: calc(100% - 250px);
        padding: 10px 20px 0 0;
        box-sizing: border-box;
        > div{
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
    }
}
</style>