<template>
    <div class="sidebar">
        <div class="title">{{title}}</div>
        <ul class="menu-list">
            <li class="menu-item" :class="{'active': $route.name === item.name}" v-for="item in data" :key="item.name" @click="menuClick(item)">{{item.meta.label}}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String,
        data: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    methods: {
        menuClick(item){
            if(item.name === 'user'){
                const token = this.$store.getters.token;
                const href = `${this.$store.getters.configMap['platformServiceUrl']}/#/system/organization-management?WHRP_TOKEN=${token}`;
                window.open(href,'_blank');
            }else{
                this.$emit('input',item.name);
                if(this.$route.name !== item.name){
                    this.$router.push({
                        name: item.name
                    })
                }
            }
            
            
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.sidebar{
    width: 250px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    .title{
        color: #0F0F0F;
        font-weight: bold;
    }
    .menu-list{
        margin-top: 15px;
        .menu-item{
            padding-left: 15px;
            height: 34px;
            line-height: 34px;
            cursor: pointer;
        }
        .menu-item:hover{
            background-color: #f5f7fa;
        }
        .menu-item.active{
            background-color: rgba(0, 255, 178, 0.15);
        }
    }
}
</style>